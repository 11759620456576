import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SortingService } from './sorting-service';
import { GridConfiguration } from './grid.model';
import { SessionStorageService } from 'angular-web-storage';
import { HasUserRoleService } from '@app/shared/dependency/has-user-role.service';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit, OnChanges {
  @Input() gridConfiguration: GridConfiguration = new GridConfiguration();
  @Input() studyLock: boolean;
  @Input() trackAuthority: boolean;

  @Output() selectedStudies: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedStudiesInput: EventEmitter<any> = new EventEmitter<any>();
  @Output() sortingColumnClickedOn: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowDataItemClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() scrollEndEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() lazyScroll: EventEmitter<any> = new EventEmitter<any>();

  selectedEntities: Array<any>;
  focusIndexSelected = -1;
  focusIndexGridData = -1;
  showtrackMonitorLinkIcon = false;
  trackScenarioName;

  constructor(
    private sortingService: SortingService,
    private localStorage: SessionStorageService,
    private accessService: HasUserRoleService
  ) {
    this.selectedEntities = [];
  }

  ngOnInit(): void {
    this.showtrackMonitorLinkIcon = false;
    if (this.gridConfiguration.rowOptions.itemsSelected.length) {
      this.setSelectedItems(this.gridConfiguration.rowOptions.itemsSelected);
    } else if (
      this.gridConfiguration.checkboxOptions.selectAllByDefault &&
      this.gridConfiguration.gridStructure.gridData
    ) {
      this.setSelectedItems(this.gridConfiguration.gridStructure.gridData);
    }
    this.setTrackMonitorIcon();
  }

  ngOnChanges(): void {}

  onScrollEnd(): void {
    this.lazyScroll.emit();
  }

  setTrackMonitorIcon(): void {
    const data = this.gridConfiguration.gridStructure.gridData;
    for (let i = 0; i < data.length; i++) {
      if (data[i].is_baseline_tracked == 1) {
        this.showtrackMonitorLinkIcon = true;
        this.trackScenarioName = data[i].scenario_name;
      }
    }
  }

  setSelectedItems(rowItems): void {
    this.selectedEntities.push(...rowItems);
    this.selectedStudies.emit(this.selectedEntities);
  }

  selectedRow(selectedEntity: any, event): void {
    if (typeof selectedEntity === 'string') {
      // this.gridConfiguration.rowOptions.highlightForData = [];
      if (event.target.checked) {
        this.selectedEntities = JSON.parse(JSON.stringify(this.gridConfiguration.gridStructure.gridData));
      } else {
        this.selectedEntities = [];
        // Change all input value to null when unchecked
        this.gridConfiguration.gridStructure.gridData.forEach(element => {
          element[this.gridConfiguration.rowOptions.inputFieldKey] = null;
        });
        selectedEntity = 'nil';
      }
    } else {
      // const i = this.gridConfiguration.rowOptions.highlightForData.indexOf(selectedEntity.country);
      // if (i >= 0) {
      //   this.gridConfiguration.rowOptions.highlightForData.splice(i, 1);
      // }
      if (event.target.checked) {
        this.selectedEntities.push(selectedEntity);
      } else {
        this.selectedEntities.forEach((element, index) => {
          if (JSON.stringify(element) === JSON.stringify(selectedEntity)) {
            this.selectedEntities.splice(index, 1);
          }
        });
        // Change the input value to null when unchecked
        if (
          this.gridConfiguration.rowOptions.inputFieldKey &&
          this.gridConfiguration.rowOptions.inputFieldKey.length > 0
        ) {
          selectedEntity[this.gridConfiguration.rowOptions.inputFieldKey] = null;
        }
      }
    }
    // remove clicked-on when checkbox is clicked
    delete selectedEntity.clickedOn;

    this.selectedStudies.emit(this.selectedEntities);
    this.rowDataItemClicked.emit(selectedEntity);
  }

  checkRow(row): boolean {
    const tempSelectedEntities = JSON.parse(JSON.stringify(this.selectedEntities));
    tempSelectedEntities.forEach(item => {
      delete item.target_no_patients_country;
    });
    const tempRow = JSON.parse(JSON.stringify(row));
    delete tempRow.target_no_patients_country;
    return JSON.stringify(tempSelectedEntities).includes(JSON.stringify(tempRow));
  }

  clickedOn(keyName, columnName): void {
    if (
      this.gridConfiguration.sortingDetails.isSortingEnabled &&
      !this.gridConfiguration.sortingDetails.unSortableColumns.includes(columnName)
    ) {
      if (keyName === (this as any).gridConfiguration.sortingDetails.sortingOptions.sortColumn) {
        if ((this as any).gridConfiguration.sortingDetails.sortingOptions.sortOrder === 'DESC') {
          (this as any).gridConfiguration.sortingDetails.sortingOptions.sortOrder = 'ASC';
          this.gridConfiguration.gridStructure.gridData = this.sortingService.commonSortByKey(
            this.gridConfiguration.gridStructure.gridData,
            keyName,
            this.gridConfiguration.columnsMetaData.integerColumnName.includes(keyName)
          );
        } else {
          (this as any).gridConfiguration.sortingDetails.sortingOptions.sortOrder = 'DESC';
          this.gridConfiguration.gridStructure.gridData = this.sortingService.commonReverseSortByKey(
            this.gridConfiguration.gridStructure.gridData,
            keyName,
            this.gridConfiguration.columnsMetaData.integerColumnName.includes(keyName)
          );
        }
      } else {
        (this as any).gridConfiguration.sortingDetails.sortingOptions.sortColumn = keyName;
        (this as any).gridConfiguration.sortingDetails.sortingOptions.sortOrder = 'ASC';
        this.gridConfiguration.gridStructure.gridData = this.sortingService.commonSortByKey(
          this.gridConfiguration.gridStructure.gridData,
          keyName,
          this.gridConfiguration.columnsMetaData.integerColumnName.includes(keyName)
        );
      }
      this.sortingColumnClickedOn.emit();
    }
  }

  rowItemClicked(row, actionButton, key?): void {
    if (
      key === this.gridConfiguration.rowOptions.rowItemKeyClicked ||
      JSON.stringify(this.gridConfiguration.columnsMetaData.actionButtons).includes(actionButton) ||
      JSON.stringify(this.gridConfiguration.columnsMetaData.actionButtonsSecondary).includes(actionButton)
    ) {
      row.clickedOn = actionButton;
      this.rowDataItemClicked.emit(row);
    }
  }

  onSpanClick(row: any, key: string): void {
    if (key === 'scenario_name') {
      row.clickedOn = key;
      this.rowDataItemClicked.emit(row);
    }
  }

  onFocus(row): void {
    this.selectedEntities.forEach((element, index) => {
      if (JSON.stringify(element) === JSON.stringify(row)) {
        this.focusIndexSelected = index;
      }
    });
    this.gridConfiguration.gridStructure.gridData.forEach((element, index) => {
      if (JSON.stringify(element) === JSON.stringify(row)) {
        this.focusIndexGridData = index;
      }
    });
  }

  onChange(row): void {
    // const i = this.gridConfiguration.rowOptions.highlightForData.indexOf(row.country);
    // this.gridConfiguration.rowOptions.highlightForData.splice(i, 1);
    this.selectedEntities[this.focusIndexSelected][this.gridConfiguration.rowOptions.inputFieldKey] =
      row[this.gridConfiguration.rowOptions.inputFieldKey];
    const temp = {
      currentRow: this.selectedEntities[this.focusIndexSelected],
      allRows: this.selectedEntities,
    };
    this.selectedStudiesInput.emit(temp);
  }

  onFocusOut(): void {
    this.focusIndexSelected = -1;
    this.focusIndexGridData = -1;
  }

  getTableHeaderAlignment(alignment): string {
    if (alignment === 'left') {
      return 'flex-start';
    } else if (alignment === 'right') {
      return 'flex-end';
    } else if (alignment === 'center') {
      return 'center';
    }
  }

  checkForHighlight(row): boolean {
    if (
      JSON.stringify(this.selectedEntities).includes(JSON.stringify(row)) ||
      this.gridConfiguration.rowOptions.highlightUnSelectedRows
    ) {
      for (const item of this.gridConfiguration.rowOptions.highlightForData) {
        // if (JSON.stringify(row).includes(item)) {
        if (row.country === item || row.region === item) {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  isCheckDisabled(row): boolean {
    // if (this.gridConfiguration.gridStructure.keyMappings.includes('actual_enrolled_patient')) {
    //   return (row.actual_enrolled_patient > 0);
    // } else
    if (
      this.gridConfiguration.checkboxOptions.disableCheckboxColumns &&
      this.gridConfiguration.checkboxOptions.disableCheckboxColumns.length > 0
    ) {
      if (
        row.scenario_status &&
        row.scenario_status.toLowerCase() ===
          this.gridConfiguration.checkboxOptions.disableCheckboxColumns[0].disableCheckBoxValue
      ) {
        return true;
      }
    }
    return false;
  }

  isActionButtonDisabled(row, button): any {
    const scenarios = this.gridConfiguration.columnsMetaData.disabledActionButtonScenarios;
    const buttons = this.gridConfiguration.columnsMetaData.disabledActionButtons;
    let studyDetails1 = this.localStorage.get('study-details');
    let studyDetails2 = this.localStorage.get('study-view');
    const userDetail = this.localStorage.get('user-details');
    let trackEnable: boolean;
    let studyDetails;
    studyDetails = studyDetails1 ? studyDetails1 : studyDetails2;

    // if (this.studyLock) {
    // if (studyDetails?.lock_updated_by === row.lock_updated_by && userDetail.user_id === studyDetails.lock_updated_by && row.scenario_lock_status) {
    trackEnable = true;
    // }
    // else {
    // trackEnable = false;
    // }
    // }

    if (
      (row.scenario_status === 'In Progress' && button === 'Download') ||
      (scenarios && scenarios.includes(row.scenario_id))
    ) {
      //console.log('-1');
      return 'button-disabled';
    } else if (button === 'Download' && row.download_status === 'disable' ) {
      //console.log('0');
        return 'button-disabled';     
    }
    if (
      (row.scenario_status === 'In Progress' && (button === 'Track & Monitor' || button === 'Clone')) ||
      (buttons && buttons.includes(button) && button !== 'Download') ||
      (scenarios && scenarios.includes(row.scenario_id))
    ) {
      //console.log('1');
      return 'button-disabled';
    }
    if( button === 'Clone' && row.forecast_type === 'refresh_forecast'){
      return 'button-disabled';
    }
    if (
      (button === 'Edit' || button === 'Delete') &&
      ((row.scenario_name == this.trackScenarioName && this.showtrackMonitorLinkIcon) || !row.user_access)
    ) {
      //console.log('2');
      return 'button-disabled';
    }
    if (button === 'Track & Monitor' && this.showtrackMonitorLinkIcon) {
      //console.log('3');
      if(row.forecast_type != 'refresh_forecast' && row.scenario_status == 'Completed') {
        return 'button-disabled';
      }
    }
    if (button === 'Edit' && row.forecast_type == 'refresh_forecast') {
      //console.log('5');
      return 'button-disabled';
    }
    if (button === 'Download' && row.user_group === 'mda') {
      //console.log('6');
      return 'button-disabled';
    }
    if (button === 'Delete' && (row.user_group === 'mda' || !row.user_access)) {
      //console.log('7');
      return 'display-none';
    }
    if (button === 'Share' && row.user_group === 'mda') {
      //console.log('8');
      return 'display-none';
    }
    if (button === 'Edit' && !row.user_access) {
      //console.log('9');
      return 'button-disabled';
    }
  }

  isLinkDisabled(row, key): any {
    const scenarios = this.gridConfiguration.columnsMetaData.disabledActionButtonScenarios;
    return scenarios && scenarios.includes(row.scenario_id) && key === 'scenario_name';
  }

  isSelectAllDisabled(): boolean {
    return false;
  }
}
