export const appSetting = {
  LOGIN: 'ctemp-user-login',
  SITE_LEVEL_UPLOAD: {
    // upload files and slsd listing
    UPLOAD_FILE: 'ctemp-upload-site-level-study-file',
    SAVE_UPLOADED_FILE: 'ctemp-save-uploaded-data',

    // CSV File Validation
    CSV_VALIDATION: 'ctemp-csv-validation',
    CSV_VALIDATION_STATUS: 'ctemp-csv-validation-status',
    GENERATE_VALIDATION_ID: 'ctemp-genarate-validation-id',
    UPLOAD_VALIDATION_STATUS: 'ctemp-validation-model-run-status',
  },
  HISTORICAL_BENCHMARK_UPLOAD: {
    // upload files and slsd listing
    UPLOAD_FILE: 'ctemp-pre-feasibility-upload-benchmark-file',
    SAVE_UPLOADED_FILE: 'ctemp-pre-feasibility-save-benchmark-file',

    // CSV File Validation
    CSV_VALIDATION: 'ctemp-pre-feasibility-benchmark-validation',
    CSV_VALIDATION_STATUS: 'ctemp-pre-feasibility-benchmark-validation-status',
    GENERATE_VALIDATION_ID: 'ctemp-pre-feasibility-genarate-validation-id',
  },
  TRACK_MONITOR_UPLOAD: {
    // upload files (Compared with Historical Bench Mark)
    UPLOAD_FILE: 'ctemp-upload-track-study-file', // Payload changed, RES Changed ***
    SAVE_UPLOADED_FILE: 'ctemp-save-track-study-file', // Payload changed, RES SAME
    // CSV File Validation (Compared with Historical Bench Mark)
    CSV_VALIDATION: 'ctemp-track-and-monitor-validation', // Payload changed, RES SAME
    CSV_VALIDATION_STATUS: 'ctemp-track-and-monitor-validation-status', // NO CHANGE
    GENERATE_VALIDATION_ID: 'ctemp-track-and-monitor-generate-validation-id', // NO CHANGE
    CHECK_TRACK_MONITOR_VALIDATION: 'ctemp-check-track-and-monitor-validation',
  },
  NEW_CHECK_ADDED: 'ctemp-check-added-places-in-track',
  CREATE_NEW_STUDY:'ctemp-create-new-tracked-study',
  
  //Track & Monitor Scenario
  TRACK_MONITOR_SCENARIO: {
    ACTUAL_PATTIENT_ENROLLED_CHART: 'ctemp-actual-patient-enrolled-chart',
    LOWER_MODELLING_CHART: 'ctemp-actual-lower-modelling-chart',
    PROBABILITY_COMPLETE_TARGET_ENROLL_CHART: 'ctemp-actual-probability-completion-chart',
    SAVE_BASELINE_TRACKED: 'ctemp-save-baseline-tracked-status',
    EDIT_PARAMETER_POST: 'ctemp-post-edit-parameter-model',
    EDIT_PARAMETER_PRE: 'ctemp-pre-edit-parameter-model',
    VALIDATE_FORECAST: 'ctemp-reforecast-validation',
    CHECK_TRACK_MONITOR_ACCESS: 'ctemp-access-track-and-monitor',
    CHECK_CONNECT_NEW_DATA: 'ctemp-check-connect-new-data',
  },

  // Connect to CTFO POST
  CONNECT_TO_CTFO: 'ctemp-connect-to-ctfo',
  CONNECT_TO_CTFO_SEARCH: 'ctemp-connect-to-ctfo-search',
  CONNECT_TO_CTFO_FILTERS: 'ctemp-connect-to-ctfo-filters',

  // Connect to CTFO PRE
  CONNECT_TO_CTFO_PRE: 'ctemp-pre-connect-to-ctfo', //used for connect and search both
  CONNCET_TO_CTFO_FILTERS_PRE: 'ctemp-pre-connect-to-ctfo-filters',
  UPLOAD_STUDY_FROM_CTFO_TO_CTEMP_PRE: 'ctemp-pre-feasibility-upload-study-from-ctfo-to-ctemp',

  // create modelling and predictions button click
  UPLOAD_STUDY_FROM_CTFO_TO_CTEMP: 'ctemp-upload-study-from-ctfo-to-ctemp',
  SELECTION_FOR_MODELLING: 'ctemp-selection-for-modelling-study-level-data',
  RUN_MODEL: 'ctemp-detail-model-run',

  // Modeling & Predictions - listing page
  STUDY_SCENARIO_COUNT: 'ctemp-get-study-scenario-count',
  VIEW_STUDIES: 'ctemp-view-studies',
  VIEW_SCENARIOS: 'ctemp-view-scenarios',

  // Compare Scenarios
  COMPARE_SCENARIOS_OUTPUT: 'ctemp-compare-scenarios',
  COMPARE_SCENARIOS_OUTPUT_PRE: 'ctemp-compare-scenarios',

  // Generating Scenario Version and Id
  SCENARIO_ID: 'ctemp-generate-scenario-id',
  VERSION_ID: 'ctemp-generate-version-id',

  // Seasonality factor
  SEASONALITY_DISPLAY_DATA: 'ctemp-display-seasonality-factor',
  SAVE_SEASONALITY_FACTOR: 'ctemp-save-seasonality-factor',
  SEASONALITY_FACTOR_FILTER_DROPDOWN: 'ctemp-dropdown-seasonality-factor',
  REFRESH_VIEW: 'ctemp-study-refresh-new',

  pre: {
    // Delete Scenario / Study
    DELETE_STUDY_SCENARIO: 'ctemp-delete-study-or-scenario',

    // Modelling Inputs
    SCENARIO_NAME_VALIDATION: 'ctemp-pre-feasibility-validate-scenario-name',
    SAVE_AND_UPDATE: 'ctemp-pre-feasibility-save-input-model-parameters',

    // Enrollment Prediction
    MEAN_ENROLLMENT_RATE: 'ctemp-pre-feasibility-mean-enrollment-rate',
    PREDICTED_PATIENT_TIME_CHART: 'ctemp-pre-feasibility-predicted-patient-time-chart',
    PROBABILITY_COMPLETE_TIME_CHART: 'ctemp-pre-feasibility-probability-complete-time-chart',
    ENROLLMENT_COMPLETION_GRID: 'ctemp-pre-feasibility-enrollment-completion-grid-data',
    SAVE_AND_COMPLETE_SCENARIO: 'ctemp-pre-feasibility-save-and-update-scenario',

    // Download Scenario / Study
    GET_CHARTS_DATA: 'ctemp-pre-get-data-for-images',
    SEND_EXPORTED_CHARTS: 'ctemp-pre-download-service',
    CHECK_DOWNLOAD_AVAILABLE: 'ctemp-check-download-available',

    //scenerio lock
    STUDY_SCENERIO_LOCK: 'ctemp-study-scenario-lock', //used
  },

  post: {
    // Delete Scenario / Study
    DELETE_STUDY_SCENARIO: 'ctemp-delete-study-or-scenario',

    // Modelling Inputs
    SCENARIO_NAME_VALIDATION: 'ctemp-validate-scenario-name',
    SAVE_AND_UPDATE: 'ctemp-save-and-update-input-model-params',

    // Enrollment Prediction
    MEAN_ENROLLMENT_RATE: 'ctemp-view-enrollment-rate',
    PREDICTED_PATIENT_TIME_CHART: 'ctemp-predicted-patient-enrollment',
    PROBABILITY_COMPLETE_TIME_CHART: 'ctemp-probablity-complete-target-enroll',
    ENROLLMENT_COMPLETION_GRID: 'ctemp-enrollment-completion-time-chart',
    SAVE_AND_COMPLETE_SCENARIO: 'ctemp-save-and-update-scenario',
    SIMULATED_ACTIVATION_CURVE_GRAPH: 'ctemp',
    LOWER_MODELLING_LEVEL: 'ctemp-enrollment-lower-modelling-level',

    // Download Scenario / Study
    GET_CHARTS_DATA: 'ctemp-get-data-for-images',
    SEND_EXPORTED_CHARTS: 'ctemp-download-service',
    CHECK_DOWNLOAD_AVAILABLE: 'ctemp-check-download-available',
  },

  // Simulation Input Parameters
  GEO_DROPDOWN: 'ctemp-pre-feasibility-geo-dropdown',
  COHORT_LIST: 'ctemp-get-cohort-list',

  // Input Model Parameters
  SCENARIO_NAME_VALIDATION: 'ctemp-validate-scenario-name',
  SAVE_AND_UPDATE: 'ctemp-save-and-update-input-model-params',
  HISTORICAL_METRICS: 'ctemp-get-study-historical-metrics',

  // Country Selection Patient Count
  COUNTRY_SELECTION_PATIENT_COUNT: 'ctemp-country-selection-and-patient-count',
  VALIDATE_COUNTRY_SELECTIONS: 'ctemp-validate-country-selection-and-patient-count',
  SAVE_COUNTRY_SELECTIONS: 'ctemp-save-country-selection-and-patient-count',
  RUN_DISTRIBUTION: 'ctemp-run-distribution-geo-modelling-psm',
  VIEW_DISTRIBUTION: 'ctemp-view-distribution-geo-modelling-psm',
  GRAPH_DISTRIBUTION: 'ctemp-distribution-graph-geo-modelling-psm',
  GET_COUNTRY_CUSTOM_ACTIVATION: 'ctemp-get-country-custom-activation',
  SAVE_COUNTRY_CUSTOM_ACTIVATION: 'ctemp-save-country-custom-activation',

  //Country custom activation PRE
  GET_COUNTRY_CUSTOM_ACTIVATION_PRE: 'ctemp-pre-get-country-custom-activation',
  SAVE_COUNTRY_CUSTOM_ACTIVATIO_PRE: 'ctemp-pre-save-country-custom-activation',

  //Geography Selection & Patient Count
  // REGION_REQUEST_PAYLOAD : 'ctemp-region-selection-and-patient-count',
  // GLOBAL_REQUEST_PAYLOAD : 'ctemp-global-selection-and-patient-count',

  // Site Activation Strategy
  VALIDATE_SITE_ACTIVATION_DATE: 'ctemp-validate-site-activation-date',
  HISTORICAL_SITE_ACTIVATION: 'ctemp-historical-site-activation',
  HISTORICAL_ACTIVATION_CURVE: 'ctemp-historical-activation-curve',
  HISTORICAL_MODEL_RUN: 'ctemp-historical-model-run',
  SIMULATED_SITE_ACTIVATION: 'ctemp-simulated-site-activation',
  SIMULATED_ACTIVATION_CURVE: 'ctemp-simulated-activation-curve',
  SIMULATION_MODEL_RUN: 'ctemp-simulation-model-run',
  RUN_ENROLLMENT_MODEL: 'ctemp-run-enrollment-model',
  RUN_ENROLLMENT_MODEL_STATUS: 'ctemp-run-enrollment-model-status',
  RUN_REFRESH_FORCAST_MODEL_POST: 'ctemp-post-refresh-forecast-model',
  RUN_REFRESH_FORCAST_MODEL_STATUS_POST: 'ctemp-run-reforecast-model-status',
  GET_HISTORICAL_FLYOUT_DATA: 'ctemp-get-historical-custom-details',
  SAVE_HISTORICAL_FLYOUT_DATA: 'ctemp-save-historical-custom-details',

  // View Enrollment Predictions - Pre Feasibility
  PRE_DISTRIBUTION_TARGET_CHART: 'ctemp-pre-feasibility-distribution-target-time-chart',
  PRE_SITE_RANDOMIZATION_CHART: 'ctemp-pre-feasibility-site-randomization-chart',

  // Study Data - Detailed View
  DETAILED_VIEW: 'ctemp-study-detailed-view',
  DETAILED_VIEW_SEARCH: 'ctemp-study-detailed-view-search',
  ADJUST_RANDOMIZATION_RATE: 'ctemp-view-adjust-randomization-rate',
  ADJUST_RANDOMIZATION_RATE_SAVE: 'ctemp-save-adjust-randomization-rate',
  DETAILED_VIEW_ACTIVITY_MODE: 'ctemp-create-user-study-activity',

  // Study Data - Historical Summary
  HISTORICAL_SUMMARY_GRAPH_DATA: 'ctemp-historical-summary-graph-map-view',

  //Study Data - Dropped Site
  DROPPED_SITE_DATA: 'ctemp-get-excluded-site-list',

  //Study Data - Refresh
  CHECK_STUDY_REFRESH_STATUS: 'ctemp-check-ctfo-study-refresh-status',
  REFRESH_STUDY_DATA: 'ctemp-refresh-study-data',
  REFRESH_NEW_STUDY: 'ctemp-refresh-create-new-study-only',
  REFRESH_NEW_STUDY_WITH_CLONE_SCENARIOS: 'ctemp-refresh-create-new-study-clonescn',

  // Pre-Feasaibility (Add Study)
  PRE_FEASIBILITY_STUDY_ID: 'ctemp-pre-feasibility-generate-study-id',
  PRE_FEASIBILITY_FILTERS: 'ctemp-pre-feasibility-get-filters',
  PRE_FEASIBILITY_SAVE: 'ctemp-pre-feasibility-save-study',

  // Simulation Input Parameters
  SIMULATION_INPUT_PARAMETER_GRID: 'ctemp-pre-feasibility-view-geo-modelling-psm',
  SIMULATION_INPUT_PARAMETER_SAVE_GRID: 'ctemp-pre-feasibility-save-geo-modelling-psm',
  SIMULATION_INPUT_PARAMETER_DROPDOWN: 'ctemp-pre-feasibility-geo-dropdown',

  // Site Activation Strategy - Pre Feasibility
  HISTORICAL_MODEL_RUN_PREF: 'ctemp-pre-feasibilty-run-historical-model',
  HISTORICAL_ACTIVATION_CURVE_PREF: 'ctemp-pre-feasibility-historical-activation-curve',
  SIMULATED_ACTIVATION_CURVE_PREF: 'ctemp-pre-feasibility-simulated-activation-curve',
  RUN_ENROLLMENT_MODEL_PREF: 'ctemp-pre-feasibility-run-enrollment-model',
  RUN_ENROLLMENT_MODEL_STATUS_PREF: 'ctemp-pre-feasibility-run-enrollment-model-status',
  RUN_REFRESH_FORCAST_MODEL_PRE: 'ctemp-pre-refresh-forecast-model',
  RUN_REFRESH_FORCAST_MODEL_STATUS_PRE: 'ctemp-run-reforecast-model-status',
  GET_LATEST_SCENARIO_DETAILS: 'ctemp-get-latest-scenario-details',
  //RUN_DISTRIBUTION: 'ctemp-run-distribution-geo-modelling-psm'
  PRE_GET_HISTORICAL_CUSTOM_DETAILS: 'ctemp-pre-get-historical-custom-details',
  PRE_SAVE_HISTORICAL_CUSTOM_DETAILS: 'ctemp-pre-save-historical-custom-details',
  PRE_SIMULATION_MODEL_RUN: 'ctemp-pre-simulation-model-run',
  GET_GLOBAL_SITE_ACTIVATION_START_END: 'ctemp-get-global-site-activation-start-end',
  VALIDATE_SITE_ACTIVATION_DATES: 'ctemp-validate-site-activation-dates',

  // Drop Sites
  SITES_EXCLUSION: 'ctemp-sites-exclusion',

  // Trial Universe PRE
  PRE_STUDY_DETAILED_VIEW: 'ctemp-pre-study-detailed-view',
  PRE_DETAILED_VIEW_FILTERS: 'ctemp-pre-detailed-view-filters',
  TRIAL_EXCLUSION: 'ctemp-trial-exclusion',
  PREFEAS_CHECK_CTFO_STUDY_REFRESH_STATUS: 'ctemp-prefeas-check-ctfo-study-refresh-status',
  REFRESH_STUDY_DATA_PRE: 'ctemp-pre-feasibility-refresh-study-data',
  REFRESH_NEW_STUDY_PRE: 'ctemp-pre-feasibility-create-new-study-only',
  REFRESH_NEW_STUDY_WITH_CLONE_SCENARIOS_PRE: 'ctemp-prefeas-create-new-study-clonescn',

  // Post Ctfo Connect empty randomisation check
  COUNT_EMPTY_RANDOMIZATION_RATE: 'ctemp-count-empty-randomization-rate',

  // Copy Scenario
  CTEMP_CLONE_SCENARIO_MAP_SCREEN: 'ctemp-clone-scenario-map-screen',
  CTEMP_GET_STUDY_PLANNED: 'ctemp-get-all-studies-ongoing-planned',
  
};
